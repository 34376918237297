/* todo: Kalmer should fix these in main.css */

.DayPicker-NavButton {
  position: static;
  z-index: 100;
}

.DayPicker-NavButton--prev {
  border: none;
  background: url('../../assets/img/ico_cal_nav.svg') 0 0 no-repeat;
  text-indent: -9999px;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.DayPicker-NavButton--prev:hover {
  background: url('../../assets/img/ico_cal_nav_hover.svg') 0 0 no-repeat;
}

.DayPicker-NavButton--next {
  border: none;
  background: url('../../assets/img/ico_cal_nav.svg') 0 0 no-repeat;
  transform: rotate(180deg);
  text-indent: -9999px;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.DayPicker-NavButton--next:hover {
  background: url('../../assets/img/ico_cal_nav_hover.svg') 0 0 no-repeat;
}

.Selectable .DayPicker-Day {
  color: #1c3548 !important;
}

.Selectable .DayPicker-Day--selected {
  background-color: #318cd3 !important;
  color: #ffffff !important;
}

.Selectable .DayPicker-Day--selected:after {
  display: none !important;
}

.Selectable
  .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  background-color: #f0f8ff !important;
  color: #4a90e2 !important;
}
