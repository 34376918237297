/*
* Global overrides that cannot be overriden any other
* way should be placed inside this file.
*/

.react-images__blanket {
  z-index: 200 !important;
}

.react-images__positioner {
  z-index: 300 !important;
}

.woocommerce-price-suffix {
  font-size: 0.75rem;
  color: #a1abb3;
}

.control {
  font-size: 1rem;
}

.modal {
  position: fixed !important;
}

@media only screen and (max-width: 768px) {
  .modal-content,
  .modal-card {
    max-width: 95%;
  }

  .modal-card-body {
    overflow: auto;
    max-height: 60vh;
  }
}

.quickview,
.modal {
  z-index: 2147483647;
}

.modal .modal-card-body input.input.is-danger,
.modal .modal-card-body textarea.textarea.is-danger {
  border: 1px solid #d0021b !important;
}

.modal .modal-card-body .select.is-success select,
.modal .modal-card-body textarea.textarea.is-success {
  border-color: #23d160 !important;
}

.panel .panel-heading a.button.is-outlined.is-loading .icon .icon-eye {
  background: none;
}

.panel .panel-block a.button.is-primary.is-loading,
.panel .panel-block.panel-footer .button.is-primary.is-loading,
.product-description .actions a.button.is-primary.is-loading {
  color: transparent !important;
  text-shadow: none !important;
}

.qty input[type='number'] {
  width: 50px;
}

.qty a.decrease,
.qty a.increase {
  margin: 0 8px;
}

.cart-row .tax_label,
.line-item .tax_label {
  display: none;
}

@media only screen and (min-width: 1024px) {
  .cart-rows .cart-row .description {
    flex-basis: 30%;
  }
}

.confirmation-step figure.image.is-placeholder {
  border: 4px solid #fff;
  box-shadow: 0 0 0 4px #e9e8e8;
}

.confirmation-step figure.image {
  border-radius: 50%;
  margin: 0 auto !important;
}

.confirmation-step .button.is-text {
  text-decoration: none !important;
  border: 1px solid #d8d8d8 !important;
}
